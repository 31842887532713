import React, {useRef, useEffect, useState} from 'react';
import {useSpring, animated} from 'react-spring';
import './AnimatedTextWithStars.css';

const AnimatedTextWithStars = () => {
    const textRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const animatedNumber = useSpring({
        number: isVisible ? 3 : 1,
        from: {number: 1},
        to: {number: 5000000},
        delay: 200,
        config: {
            duration: 3000, // Adjust the duration as needed
        },
    });

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Adjust the threshold value as needed
        };

        const observer = new IntersectionObserver(handleIntersect, options);

        if (textRef.current) {
            observer.observe(textRef.current);
        }

        return () => {
            if (textRef.current) {
                observer.unobserve(textRef.current);
            }
        };
    }, []);

    const handleIntersect = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                entry.target.classList.add('animated');
            } else {
                setIsVisible(false);
                entry.target.classList.remove('animated');
            }
        });
    };

    return (
        <div className="animated-text-with-stars">
            <div className="text-container">
                <div className={"tiny"}>
                    Somewhere between 1 and
                </div>
                <div className="animated-text" ref={textRef}>
                    <animated.span>{animatedNumber.number.interpolate((n) => n.toLocaleString(undefined, {maximumFractionDigits: 0}))}</animated.span>
                </div>
                <div>
                    users
                </div>
            </div>
            <div className="stars-container">
                <span className={"tiny"}>Between 0 and</span>
                <div className="animated-stars"></div>
                <div className="reviews-text"> thousands of 5-star reviews</div>
            </div>
        </div>
    );
};

export default AnimatedTextWithStars;
