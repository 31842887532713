import './App.css';
import HeroSection from "./hero/Hero";
import Reviewsection from "./reviewsection";
import Footer from "./hero/footer";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </Router>
    );
}

const Home = () => {
    return (
        <div>
            <Footer/>
            <HeroSection/>
            <Reviewsection/>
            <Footer/>
        </div>
    );
};

export default App;
