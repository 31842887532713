import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Heading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const PrivacyPolicy = () => {
    return (
        <Wrapper>
            <Heading>Privacy Policy</Heading>
            <Paragraph>
                At predictgaming.com, we are committed to protecting your privacy. This Privacy Policy outlines how we
                collect, use, disclose, and protect the personal information of our users ("you" or "user") when you use
                our App.
            </Paragraph>
            <Paragraph>
                <strong>1. Information Collection and Use</strong>
            </Paragraph>
            <Paragraph>
                1.1 Personal Information: We may collect certain personally identifiable information, such as your name,
                email address, and any other information voluntarily provided by you when you use the App.
            </Paragraph>
            <Paragraph>
                1.2 Usage Data: The App may collect certain non-personal information about your device, including but
                not limited to your device type, operating system, unique device identifier, and IP address. This data
                is used to analyze trends, administer the App, track users' movements, and gather demographic
                information.
            </Paragraph>
            <Paragraph>
                <strong>2. Data Storage and Security</strong>
            </Paragraph>
            <Paragraph>
                2.1 Data Storage: We securely store the collected personal information on servers hosted by reputable
                cloud service providers. Your information may be stored and processed in the country where our servers
                are located or in other jurisdictions.
            </Paragraph>
            <Paragraph>
                2.2 Data Security: We employ industry-standard security measures to protect your personal information
                from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no
                method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee
                absolute security of your data.
            </Paragraph>
            <Paragraph>
                <strong>3. Data Sharing and Disclosure</strong>
            </Paragraph>
            <Paragraph>
                3.1 Third-Party Service Providers: We may engage trusted third-party service providers to perform
                functions and services necessary for the operation of the App. These providers may have access to your
                personal information only to the extent required to perform their functions and are obligated to
                maintain the confidentiality and security of your data.
            </Paragraph>
            <Paragraph>
                3.2 Legal Requirements: We may disclose your personal information if required to do so by law or in
                response to valid requests by public authorities (e.g., law enforcement agencies).
            </Paragraph>
            <Paragraph>
                <strong>4. Children's Privacy</strong>
            </Paragraph>
            <Paragraph>
                The App is not intended for children under the age of 13. We do not knowingly collect personal
                information from children under 13. If we become aware that we have collected personal information from
                a child under 13 without parental consent, we will take steps to remove the information from our
                servers.
            </Paragraph>
            <Paragraph>
                <strong>5. Changes to the Privacy Policy</strong>
            </Paragraph>
            <Paragraph>
                We reserve the right to update or modify this privacy policy at any time. Any changes will be effective
                immediately upon posting the updated policy in the App. Your continued use of the App after the posting
                of any modifications constitutes your acceptance of the revised privacy policy.
            </Paragraph>
            <Paragraph>
                <strong>6. Contact Us</strong>
            </Paragraph>
            <Paragraph>
                If you have any questions or concerns about this privacy policy or the App's data practices, please
                contact us at <Link href="mailto:support@predictgaming.com">support@predictgaming.com</Link>.
            </Paragraph>
        </Wrapper>
    );
};

export default PrivacyPolicy;
