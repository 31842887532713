import React from 'react';
import './HeroSection.css';
import heroImage from './hero-image.png';
import appStoreImage from './app-store.png';
import playStoreImage from './play-store.png';
import Logo from './no-bg.png';

const HeroSection = () => {
    return (
        <div className="hero-container">
            <img src={heroImage} alt="Hero" className="hero-image"/>
            <div className="logo-overlay">
                <img src={Logo} alt="Logo" className="logo-image"/>
            </div>
            <div className="hero-overlay">
                <h1 className="hero-title">Rugby World Cup 2023 Predictor Game</h1>
                <div className="app-store-buttons">
                    <a href="https://predictgaming.com/download?force-platform=iOS-web" target="_blank" rel="noopener noreferrer">
                        <img src={appStoreImage} alt="Download from App Store" className="app-store-image"/>
                    </a>
                    <a href="https://predictgaming.com/download?force-platform=android-web" target="_blank" rel="noopener noreferrer">
                        <img src={playStoreImage} alt="Download from Play Store" className="play-store-image"/>
                    </a>
                </div>
            </div>
            <div className="scroll-down"></div>
        </div>
    );
};

export default HeroSection;
