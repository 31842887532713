import React from 'react';
import './footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top"></div>
            <div className="footer-content">
                <span className="logo-text">predictgaming.com</span>
            </div>
        </footer>
    );
};

export default Footer;
